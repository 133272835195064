import React from 'react';
import {graphql} from 'gatsby';
import {Page} from '../components/Page';
import {Markdown} from '../components/Markdown';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import PageHeader from '../components/PageHeader';
import PageSubHeader from '../components/PageSubHeader';
import {Helmet} from "react-helmet";

export default function Portfolio({data}) {
	const {portfolioPage: {header, bodyText,titleSEO, descriptionSEO}, allPortfolio: {nodes: portfolios}} = data;
	return <Page>
		<Helmet>
			<title>{titleSEO}</title>
			<meta name="description" content={descriptionSEO} />
	{/*		<link rel="canonical" href="https://www.icit.com.au/portfolio" />*/}
		</Helmet>
		<PageHeader>
			Portfolio
		</PageHeader>
		<PageSubHeader>
			{header}
		</PageSubHeader>
		<Markdown>
			{bodyText}
		</Markdown>
		<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', gap: 20}}>
			{portfolios.map(portfolio => <PortfolioItem key={portfolio.id} portfolio={portfolio} />)}
		</div>
	</Page>;
}

function PortfolioItem({portfolio}) {
	return <div className="PortfolioItem">
		<a href={portfolio.link} target="_blank">
			<GatsbyImage
				image={getImage(portfolio.image.localFile)}
				alt={portfolio.title}
			/>
			<h3>{portfolio.title}</h3>
		</a>
	</div>;
}

export const query = graphql`
    query {
        portfolioPage {
            header
            bodyText
			descriptionSEO
			titleSEO
        }
	  allPortfolio(
		filter: {published: {eq: true}, active: {eq: true}}
		sort: {fields: created, order: DESC}
	  ) {
		nodes {
		  id
		  title
		  link
		  image {
			localFile {
			  childImageSharp {
				gatsbyImageData(
				  height: 225
				  width: 300
				  formats: [AUTO, WEBP]
				)
			  }
			}
		  }
		}
	  }
    }
`;
